import { VideoAnalyticsModule } from './video-analytics/video-analytics.module';
import { WorkforcesolutionsModule } from './workforcesolutions/workforcesolutions.module';
import { MobileAppsServicesModule } from './mobile-apps-services/mobile-apps-services.module';
import { DataAnalyticServicesModule } from './data-analytic-services/data-analytic-services.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('../app/home/home.module').then(m => m.HomeModule) },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'about-us', loadChildren: () => import('../app/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'expertise', loadChildren: () => import('../app/expertise/expertise.module').then(m => m.ExpertiseModule) },
  { path: 'workforcesolutions', loadChildren: () => import('../app/workforcesolutions/workforcesolutions.module').then(m => m.WorkforcesolutionsModule) },
  { path: 'capabilities', loadChildren: () => import('../app/capabilities/capabilities.module').then(m => m.CapabilitiesModule) },
  { path: 'reach-us', loadChildren: () => import('../app/reach-us/reach-us.module').then(m => m.ReachUsModule) },
  { path: 'banking-service', loadChildren: () => import('../app/banking-service/banking-service.module').then(m => m.BankingServiceModule) },
  { path: 'case-study', loadChildren: () => import('../app/case-study/case-study.module').then(m => m.CaseStudyModule) },
  { path: 'ewallet', loadChildren: () => import('../app/ewallet/ewallet.module').then(m => m.EwalletModule) },
  { path: 'career', loadChildren: () => import('../app/career/career.module').then(m => m.CareerModule) },
  { path: 'transport', loadChildren: () => import('../app/transport/transport.module').then(m => m.TransportModule) },
  { path: 'manufacturing', loadChildren: () => import('../app/manufacturing/manufacturing.module').then(m => m.ManufacturingModule) },
  { path: 'e-learning', loadChildren: () => import('../app/e-learning/e-learning.module').then(m => m.ELearningModule) },
  { path: 'retail', loadChildren: () => import('../app/retail/retail.module').then(m => m.RetailModule) },
  { path: 'healthcare', loadChildren: () => import('../app/healthcare/healthcare.module').then(m => m.HealthcareModule) },
  { path: 'telecom', loadChildren: () => import('../app/telecom/telecom.module').then(m => m.TelecomModule) },
  { path: 'gaming', loadChildren: () => import('../app/gaming/gaming.module').then(m => m.GamingModule) },

  { path: 'ai-ml-services', loadChildren: () => import('../app/ai-ml-services/ai-ml-services.module').then(m => m.AiMlServicesModule) },
  { path: 'data-analytics-services', loadChildren: () => import('../app/data-analytic-services/data-analytic-services.module').then(m => m.DataAnalyticServicesModule) },
  { path: 'mobile-app-development-services', loadChildren: () => import('../app/mobile-apps-services/mobile-apps-services.module').then(m => m.MobileAppsServicesModule) },
  { path: 'application-development-maintenance-services', loadChildren: () => import('../app/application-development-services/application-development-services.module').then(m => m.ApplicationDevelopmentServicesModule) },
  { path: 'web-development-services', loadChildren: () => import('../app/web-development-services/web-development-services.module').then(m => m.WebDevelopmentServicesModule) },
  { path: 'testing-services', loadChildren: () => import('../app/testing-services/testing-services.module').then(m => m.TestingServicesModule) },
  { path: 'digital-marketing-services', loadChildren: () => import('../app/seodigital-marketing-services/seodigital-marketing-services.module').then(m => m.SEODigitalMarketingServicesModule) },
  { path: 'work-force-solution-services', loadChildren: () => import('../app/work-force-solutions-services/work-force-solutions-services.module').then(m => m.WorkForceSolutionsServicesModule) },
  { path: 'offshore-development-services', loadChildren: () => import('../app/offshore-development-services/offshore-development-services.module').then(m => m.OffshoreDevelopmentServicesModule) },

  { path: 'vehicleinspection', loadChildren: () => import('../app/vehicleinspection/vehicleinspection.module').then(m => m.VehicleinspectionModule) },
  { path: 'smartbattery', loadChildren: () => import('../app/smartbattery/smartbattery.module').then(m => m.SmartbatteryModule) },
  { path: 'acdperformance', loadChildren: () => import('../app/acdperformance/acdperformance.module').then(m => m.AcdperformanceModule) },
  { path: 'aidigital', loadChildren: () => import('../app/aidigital/aidigital.module').then(m => m.AidigitalModule) },
  { path: 'smarthome', loadChildren: () => import('../app/smarthome/smarthome.module').then(m => m.SmarthomeModule) },
  { path: 'serviceparts', loadChildren: () => import('../app/serviceparts/serviceparts.module').then(m => m.ServicepartsModule) },
  { path: 'analyticsplatform', loadChildren: () => import('../app/analyticsplatform/analyticsplatform.module').then(m => m.AnalyticsplatformModule) },
  { path: 'enterprisemobileapp', loadChildren: () => import('../app/enterprisemobileapp/enterprisemobileapp.module').then(m => m.EnterprisemobileappModule) },
  { path: 'clinicaltrails', loadChildren: () => import('../app/clinicaltrails/clinicaltrails.module').then(m => m.ClinicaltrailsModule) },
  { path: 'testcasemanagement', loadChildren: () => import('../app/testcasemanagement/testcasemanagement.module').then(m => m.TestcasemanagementModule) },
  { path: 'onlinegaming', loadChildren: () => import('../app/onlinegaming/onlinegaming.module').then(m => m.OnlinegamingModule) },
  { path: 'contestplatform', loadChildren: () => import('../app/contestplatform/contestplatform.module').then(m => m.ContestplatformModule) },
  { path: 'cropsresearch', loadChildren: () => import('../app/cropsresearch/cropsresearch.module').then(m => m.CropsresearchModule) },
  { path: 'image-enhancer', loadChildren: () => import('../app/image-enhancer/image-enhancer.module').then(m => m.ImageEnhancerModule) },
  { path: 'video-analytics', loadChildren: () => import('../app/video-analytics/video-analytics.module').then(m => m.VideoAnalyticsModule) },
  { path: 'ai-powered-face-mask-detection', loadChildren: () => import('../app/ai-powered-face-mask-detection/ai-powered-face-mask-detection.module').then(m => m.AiPoweredFaceMaskDetectionModule) },
  { path: 'coming-soon', loadChildren: () => import('../app/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
  { path: 'aws', loadChildren: () => import('../app/aws/aws.module').then(m => m.AwsModule) },
  { path: 'pcb-detection', loadChildren: () => import('../app/pcb-detection/pcb-detection.module').then(m => m.PcbDetectionModule) },
 
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', scrollOffset: [0, 80] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
