<div class="expertiseHeader">
  <div class="container">
    <div class="custom-container">
      <div class="row pt-5">
        <div class="py-5">
          <h6 class="text-uppercase pt-5"><a class="text-decoration-none fontBold" href="">Home</a> &nbsp;&nbsp;>>
            &nbsp;&nbsp;<a class="text-decoration-none fontBold" [routerLink]="['/capabilities']">services we
              offer</a>&nbsp;&nbsp;>> <span class="caseStudyDisplay">&nbsp;&nbsp;</span><span>ai and ml</span>
          </h6>
          <h2 class="text-uppercase py-4">Retail video analytics</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="careerJobLists">
  <div class="container jobSubtitle">
    <div class="custom-container">
      <div class="row py-5">
        <div class="col-md-8 my-auto">
          <div data-aos="fade-up" data-aos-duration="900">
            <h6 class="m-0 text-uppercase text-white">Introduction</h6>
            <p class="m-0 py-3">
              Retail video analytics has emerged as a powerful tool for understanding customer behavior, optimizing store layout, and enhancing the overall shopping experience. By leveraging computer vision and deep learning techniques, retailers can extract valuable insights from video footage, including demographic information such as age and gender, as well as foot traffic metrics like people count and region-based count within a store.  
            </p>
        </div>
        </div>

        <div class="col-md-4 my-auto">
          <img class="img-fluid" src="../../assets/images/imageenhancer.png" alt="imageenhancer">

        </div>

    </div>

<div class="row">
  <div class="col-md-6">
    <div class="row pb-5">
      <div class="col-md-4">
        <img class="img-fluid" src="../../assets/images/imageenhancerscreentwo.png" alt="imageenhancer">

      </div>

      <div class="col-md-8">
        <p>
          An example input image of dimensions 236x236 pixels is showcased, highlighting the image that requires enhancement.
        </p>
      </div>

  </div>
  </div>
  <div class="col-md-6">
    <div class="row pb-5">
      
      <div class="col-md-4">
        <img class="img-fluid" src="../../assets/images/qualityimageenhance.png" alt="imageenhancer">
  
      </div>

      <div class="col-md-8">
        <p>
          The result page presents the original and enhanced images side by side, with a slider feature at the center. Users can dynamically adjust the slider to compare the original and enhanced versions. Moving the slider to the right displays the original image, while shifting it to the left reveals the enhanced version.
        </p>
      </div>
  
      
  
  </div>
  </div>
</div>


  
<div class="row">
  <div class="col-md-6">
    <div class="row pb-5">
      
      <div class="col-md-4">
        <img class="img-fluid" src="../../assets/images/imagequalityenhance.png" alt="imageenhancer">
    
      </div>
    
      <div class="col-md-8">
        <p>
          When the slider is moved towards the right, users can observe the original image, which may exhibit blurriness and noise, emphasizing the need for enhancement.
        </p>
      </div>
    
    </div>
  </div>

  <div class="col-md-6">
    <div class="row pb-5">

      <div class="col-md-4">
        <img class="img-fluid" src="../../assets/images/imagequalityenhancer.png" alt="imageenhancer">
    
      </div>

      <div class="col-md-8">
        <p>
          Conversely, moving the slider towards the left unveils the enhanced image, characterized by smoother visuals and enhanced color rendition, demonstrating the application's capability to improve image quality.
        </p>
      </div>
          
    
    
    
    
    </div>
    
  </div>

</div>

    </div>
  </div>
  </div>




  <div class="FeaturesList">
    <div class="container">
      <div class="custom-container">

        <div class="row pb-5 keyFeatures">
          <div class="col-md-12">
            <h6 class="ttext-capitalize pt-5">Key Features of the Image Quality Enhancer</h6>
            <ul>
                <li><span>User-Friendly Interface: </span>Featuring a simple interface, users can easily navigate through the application's functionalities.</li>
                <li><span>Upload Images: </span>Users can initiate the enhancement process by uploading their input images with the "Choose file" button on the home page.</li>
                <li><span>Real-Time Results: </span>Users can view the enhancement results in real-time, allowing for immediate feedback and adjustments.</li>
                <li><span>Comparison Slider: </span>The application provides a comparison slider feature on the result page, enabling users to dynamically adjust and compare the original and enhanced versions of the image.</li>
                <li><span>Original Image View: </span>Users can observe the original image on one side of the slider, highlighting areas that require improvement such as blurriness and noise.</li>
                <li><span>Enhanced Image View: </span>On the other side of the slider, users can view the enhanced image, showcasing smoother visuals and improved colour rendition.</li>
                <li><span>Enhancement Parameters: </span>Users have the flexibility to adjust enhancement parameters using sliders, allowing for customized improvements tailored to their preferences.</li>
                <li><span>Versatile Usage: </span>Suitable for both budding photographers and casual users, the Image Enhancer brings out the best in every image, regardless of the source.</li>
                <li><span>Game-Changing: </span>The application is a game-changer in image editing, making it easier than ever to transform mediocre photos into stunning works of art.</li>
                <li><span>Intuitive: </span>With its intuitive interface and powerful features, the Image Enhancer simplifies the process of enhancing image quality, ensuring user satisfaction.</li>
                <li><span>Makeover: </span>Give your pictures the makeover they deserve with the Image Enhancer, providing an unparalleled enhancement experience.</li>
              
            </ul>
          </div>
        
        </div>

        </div>
      </div>
    </div>

